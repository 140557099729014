<template>
	<layout-variant-two>
		<template v-slot:body>
			<div class="register-page-con">
				<register-check-email
					@proceed-otp="handleProceedOTP"
					@proceed-register="handleProceedRegister"
					v-if="checkRegistration && !proceedOTP && !proceedRegister"
					ref="registerCheckEmail"
				></register-check-email>
				<one-time-password
					:email="email"
					v-if="proceedOTP"
					:congratulateUser="congratulateUser"
				></one-time-password>

				<template v-if="proceedRegister">
					<register-question></register-question>
					<div class="register-progress-con">
						<base-slab
							:totalIndex="totalIndex + 1"
							:currentIndex="currentIndex"
							@slab-clicked="changeIndex"
						></base-slab>
						<base-button
							class="sd-btn-primary sd-btn-lg page__button"
							:isFullWidth="true"
							btnLabel="Continue"
							:isLoading="isLoading"
							:isDisabled="isLoading || !currentAnswer"
							@click="handleClick"
						></base-button>
					</div>
				</template>
			</div>
		</template>
	</layout-variant-two>
</template>

<script>
import LayoutVariantTwo from "@/components/layout/LayoutVariantTwo.vue";
import BaseSlab from "../page-survey/components/BaseSlab.vue";
import RegisterQuestion from "./components/RegisterQuestion.vue";
import RegisterCheckEmail from "./components/RegisterCheckEmail.vue";
import OneTimePassword from "@/presentation/components/OneTimePassword.vue";
import { registerCustomer } from "@/connector/customerConnector";
import { mapGetters } from "vuex";
import utility from "@/presentation/mixins/utility";

export default {
	mixins: [utility],
	components: {
		LayoutVariantTwo,
		BaseSlab,
		RegisterQuestion,
		RegisterCheckEmail,
		OneTimePassword,
	},
	props: {
		passedEmail: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			email: "",
			checkRegistration: true,
			proceedOTP: false,
			proceedRegister: false,
			isLoading: false,
			congratulateUser: false,
		};
	},
	created() {
		this.$store.dispatch("register/populateWithStaticQuestions");
	},
	mounted() {
		if (this.passedEmail) {
			this.$refs.registerCheckEmail.email = this.passedEmail;
			this.email = this.passedEmail;
			this.proceedRegister = true;
		}
	},
	methods: {
		handleProceedOTP(email) {
			this.proceedOTP = true;
			this.email = email;
		},
		handleProceedRegister(email) {
			this.proceedRegister = true;
			this.email = email;
		},
		async handleClick() {
			if (this.currentQuestion.apiField == "phone") {
				let isValidPhone = this.isValidPhone(this.currentAnswer);

				if (!isValidPhone) {
					this.showNotification(
						"alert",
						"error_outline",
						"Please input a valid 8-digit mobile number."
					);

					return;
				}
			}

			if (this.currentIndex == this.totalIndex) {
				// do registration
				let registrationParams = {
					...{ email: this.email },
					...this.mappedAnswers,
				};
				this.isLoading = true;
				await this.registerCustomer(registrationParams);
				this.isLoading = false;
			}
			this.$store.commit("register/incrementCurrentIndex");
		},
		async registerCustomer(customerParams) {
			try {
				let response = await registerCustomer(customerParams);
				if (response.success) {
					// logging in the customer
					this.proceedRegister = false;
					this.proceedOTP = true;
					this.congratulateUser = true;

					return;
				} else {
					this.showNotification(
						"alert",
						"error_outline",
						response.message
					);
				}
			} catch (error) {
				this.showNotification(
					"alert",
					"error_outline",
					"Network Error"
				);
			}
		},
		changeIndex(index) {
			this.$store.commit("register/changeCurrentIndex", index);
		},
	},
	computed: {
		...mapGetters({
			currentAnswer: "register/getCurrentAnswer",
			mappedAnswers: "register/getMappedAnswers",
			registerQuestions: "register/getQuestions",
			totalIndex: "register/getQuestionsLength",
			currentIndex: "register/getCurrentIndex",
		}),
		currentQuestion() {
			if (this.registerQuestions) {
				return this.registerQuestions[this.currentIndex];
			}
			return null;
		},
	},
};
</script>

<style scoped lang="scss">
.register-page-con {
	padding: 24px;
	height: 100%;
	display: flex;
	flex-direction: column;
}
.register-progress-con {
	margin-top: auto;
}

.page__button {
	margin-top: 16px;
}
</style>
