<template>
	<!-- Just a dummy, refactor this -->
	<Transition name="modal">
		<div v-if="show" class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-container">
					<div class="modal-header">
						<p>
							{{ fulfillmentText }}
						</p>
					</div>

					<div class="modal-body">Date and Time Selection</div>

					<div class="modal-footer">
						<base-button
							btnLabel="confirm"
							class="sd-btn-primary sd-btn-sm"
							@click="emitCloseModal"
						/>
					</div>
				</div>
			</div>
		</div>
	</Transition>
</template>

<script>
export default {
	name: "BaseModal",
	props: {
		show: Boolean,
		fulfillmentType: String,
	},
	data() {
		return {};
	},
	computed: {
		fulfillmentText() {
			let fulfillmentText = "DELIVERY";
			if (this.fulfillmentType) {
				fulfillmentText = this.fulfillmentType.toUpperCase();
			}
			return fulfillmentText;
		},
	},
	methods: {
		// remove this
		emitCloseModal() {
			this.$emit("close-modal");
		},
	},
	created() {},
	mounted() {},
	unmounted() {},
	watch: {
		show(val) {
			if (val) {
				// reset data, should be a re-render instead, find an effective way to do this in 'teleport'
			}
		},
	},
};
</script>

<style lang="scss" scoped>
// modal
.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

.modal-container {
	max-width: 400px;
	height: 300px;
	padding: 20px;
	overflow: scroll;
	margin: 0px auto;
	background-color: #fff;
	border-radius: 15px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease;
	display: flex;
	flex-direction: column;
	gap: 30px;
	align-items: center;
}

.modal-header h3 {
	margin-top: 0;
	color: #42b983;
}

.modal-body {
	height: 50%;
}

// .modal-body {
// 	margin: 20px 0;
// }

.modal-default-button {
	float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
	opacity: 0;
}

.modal-leave-to {
	opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
	transform: scale(1.1);
}
</style>
