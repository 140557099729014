import questions from "../../presentation/pages/page-register/questions";
const state = () => ({ questions: [], currentIndex: 0 });

const mutations = {
	updateRegistrationData(state, questions) {
		state.questions = questions;
	},
	updateAnswer(state, value) {
		if (
			["input", "date"].includes(state.questions[state.currentIndex].type)
		) {
			state.questions[state.currentIndex].answer = value;
		} else {
			state.questions[state.currentIndex].answers.forEach((e) => {
				if (e.title === value.title) {
					e.selectedAnswer = true;
				} else {
					e.selectedAnswer = false;
				}
			});
		}
	},
	incrementCurrentIndex(state) {
		if (state.currentIndex < state.questions.length - 1) {
			state.currentIndex++;
		}
	},

	changeCurrentIndex(state, index) {
		if (state.currentIndex < index) {
			return;
		}
		state.currentIndex = index;
	},
};
const actions = {
	populateWithStaticQuestions({ commit }) {
		commit("updateRegistrationData", questions);
	},
};
const getters = {
	getCurrentIndex(state) {
		return state.currentIndex;
	},
	getCurrentQuestion(state) {
		return state.questions[state.currentIndex];
	},
	getCurrentAnswer(state) {
		if (state.questions) {
			if (state.questions[state.currentIndex].type == "single-ans")
				return state.questions[state.currentIndex].answers.find(
					(it) => it.selectedAnswer
				)?.enum;

			return state.questions[state.currentIndex].answer;
		}
		return null;
	},
	getCurrentAnswers(state) {
		if (state.questions) {
			return state.questions[state.currentIndex].answers;
		}
		return null;
	},
	getAnswerType(state) {
		return state.questions[state.currentIndex].type;
	},
	getQuestions(state) {
		if (state.questions) {
			return state.questions;
		}
		return null;
	},
	getMappedAnswers(state) {
		if (state.questions) {
			let temp = state.questions.map((it) => {
				switch (it.type) {
					case "date":
						return { [it.apiField]: new Date(it.answer).getTime() };
					case "single-ans":
						return {
							[it.apiField]: it.answers.find(
								(answer) => answer.selectedAnswer
							)?.enum,
						};
					default:
						return { [it.apiField]: it.answer };
				}
			});

			return temp.reduce((acc, x) => {
				for (let key in x) acc[key] = x[key];
				return acc;
			}, {});
		}
	},
	getQuestionsLength(state) {
		if (state.questions) {
			return state.questions.length - 1;
		}
		return null;
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
