<template>
	<div class="sd-base-con">
		<div class="sd-main-con">
			<slot name="body"> body</slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "StartPageLayout",
};
</script>

<style scoped lang="scss">
.sd-header {
	width: 100%;
}

.sd-main-con {
	height: 100%;
	width: 100%;
	position: relative;
	justify-content: center;
}

@media (min-width: 480px) {
	.sd-main-con {
		max-width: 480px;
	}
}

@media (min-width: 672px) {
	.sd-main-con {
		max-width: 672px;
	}
}
</style>
