<template>
	<div v-if="!currentQuestion"></div>
	<div v-else>
		<div class="question">
			<p class="question__title heading-4">{{ currentQuestion.title }}</p>
			<p class="question__subheading body-2-normal">
				{{ currentQuestion.subtitle }}
			</p>
		</div>
		<div>
			<register-answer
				:placeholder="currentQuestion.placeholder"
				class="question__answer"
			></register-answer>
		</div>
	</div>
</template>

<script>
import RegisterAnswer from "./RegisterAnswer.vue";
import { mapGetters } from "vuex";
export default {
	methods: {
		handleClick(index) {
			this.$emit("answerClicked", index);
		},
	},
	computed: {
		...mapGetters({
			currentQuestion: "register/getCurrentQuestion",
			currentIndex: "register/getCurrentIndex",
		}),
	},
	components: { RegisterAnswer },
};
</script>

<style scoped lang="scss">
.question {
	text-align: left;
	&__number {
		color: $secondary-color-40;
		margin-top: 50px;
	}
	&__title {
		margin-top: 24px;
		color: $secondary-color-100;
	}
	&__subheading {
		color: $secondary-color-40;
		margin-top: 16px;
	}

	&__answer {
		margin-top: 24px;
	}
}
</style>
