<template>
	<div class="page__content">
		<p class="page__content__comment heading-5">Almost done!</p>
		<p class="page__content__title heading-4">
			Please enter your one-time password
		</p>
		<p class="page__content__subheading body-2-normal">
			Enter the 6 digit code we have sent to your email address
		</p>
		<div class="page__content__code">
			<code-boxes
				@submit-code="codeSubmissionHandler"
				:totalBoxes="boxes"
			></code-boxes>
		</div>

		<p
			v-show="disableSendOTP"
			class="page__content__resend body-2-normal disabled"
		>
			Resend code in {{ otpTimer }} seconds
		</p>
		<p
			@click="resendOTPHandler"
			v-show="!disableSendOTP"
			class="page__content__resend body-2-normal"
		>
			Resend OTP code
		</p>
	</div>
</template>

<script>
import CodeBoxes from "@/presentation/components/CodeBoxes.vue";
import { loginWithOTP, sendOTP } from "@/connector/customerConnector";
import utility from "@/presentation/mixins/utility.js";
export default {
	components: {
		CodeBoxes,
	},
	mixins: [utility],
	props: {
		email: {
			type: String,
			required: true,
		},
		congratulateUser: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			boxes: 6,
			disableSendOTP: true,
			otpTimer: 90,
			otpTimerFunction: null,
		};
	},
	methods: {
		async sendOTP(email) {
			try {
				let otpResponse = await sendOTP(email);
				if (!otpResponse || !otpResponse.success)
					this.showNotification(
						"alert",
						"error_outline",
						"Error occured when sending OTP"
					);
			} catch (error) {
				this.showNotification(
					"alert",
					"error_outline",
					"Error occured when sending OTP"
				);
			}
		},
		codeSubmissionHandler(otpCode) {
			this.loginWithOTP(otpCode, this.email);
		},
		resendOTPHandler() {
			this.disableSendOTP = true;
			this.sendOTP(this.email);
			this.otpTimer = 90;
			this.startOTPTimer();
		},
		startOTPTimer() {
			this.otpTimerFunction = setInterval(() => {
				if (this.otpTimer > 0) this.otpTimer -= 1;
				else this.clearOTPTimer();
			}, 1000);
		},
		clearOTPTimer() {
			clearInterval(this.otpTimerFunction);
			this.disableSendOTP = false;
		},
		async loginWithOTP(otpCode, email) {
			try {
				let login = await loginWithOTP({
					email,
					otp: otpCode,
				});

				// TODO: refactor this into service layer
				if (login.success) {
					this.$store.dispatch("setLoginToken", login.authToken);
					this.$store.dispatch("setCustomer", login.customer);
					if (this.$route.query.redirect) {
						this.$router.push(this.$route.query.redirect);
					} else {
						if (this.congratulateUser) {
							this.$router.push({
								name: "ThankYouPage",
								params: {
									title: "Congrats!",
									subTitle: "And welcome to Better For Me",
									instruction:
										"Let's embark on this health journey together!",
									buttonLabel: "Start!",
									goToPage: "HomePage",
								},
							});
							return;
						}

						this.goTo("HomePage");
					}
				} else {
					this.showNotification(
						"alert",
						"error_outline",
						"OTP codes are not valid."
					);
				}
			} catch (error) {
				this.showNotification(
					"alert",
					"error_outline",
					"Error logging in"
				);
			}
		},
	},
	async mounted() {
		this.startOTPTimer();
		await this.sendOTP(this.email);
	},
	unmounted() {
		this.clearOTPTimer();
	},
};
</script>
<style lang="scss" scoped>
.page {
	&__content {
		text-align: left;
		&__comment {
			color: $secondary-color-40;
			margin-top: 24px;
		}
		&__title {
			margin-top: 24px;
		}
		&__subheading {
			margin-top: 16px;
			color: $secondary-color-40;
		}
		&__resend {
			text-align: center;
			color: $link-blue-color;
			cursor: pointer;
			margin-top: 16px;

			&.disabled {
				cursor: not-allowed;
				color: $secondary-color-40;
			}
		}
		&__code {
			margin-top: 24px;
		}
	}
}

@media (min-width: 672px) {
	.page {
		&__content {
			text-align: center;
		}
	}
}
</style>
