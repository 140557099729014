<template>
	<div>
		<div v-if="answerType === 'input'">
			<input
				class="inputField"
				type="text"
				:placeholder="placeholder"
				v-model="answer"
			/>
		</div>
		<div v-else-if="answerType === 'date'">
			<input class="inputField" type="number" v-model="answer" />
		</div>

		<div v-else>
			<div
				v-for="answer in answers"
				:key="answer.title"
				class="answer"
				:class="{ 'answer--selected': answer.selectedAnswer }"
				@click="handleClick(answer)"
			>
				{{ answer.title }}
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	methods: {
		handleClick(answer) {
			this.$store.commit("register/updateAnswer", answer);
		},
	},
	props: {
		placeholder: {
			type: String,
			default: "",
		},
	},
	computed: {
		...mapGetters({
			currentIndex: "register/getCurrentIndex",
			answerType: "register/getAnswerType",
		}),
		answer: {
			get() {
				return this.$store.getters["register/getCurrentAnswer"];
			},
			set(value) {
				this.$store.commit("register/updateAnswer", value);
			},
		},
		answers: {
			get() {
				return this.$store.getters["register/getCurrentAnswers"];
			},
			set(value) {
				this.$store.commit("register/updateAnswer", value);
			},
		},
	},
};
</script>

<style lang="scss" scoped>
.inputField {
	border: none;
	width: 100%;
	height: 52px;
	background: $primary-color-10;
	border-radius: 12px;
	padding: 16px;
	/* Chrome, Safari, Edge, Opera */
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	[type="number"] {
		-moz-appearance: textfield;
	}
}

.answer {
	padding: 16px;
	margin-top: 8px;
	border-radius: 1rem;
	border: none;
	background-color: #fffbeb;
	width: 100%;
	text-align: left;
	transition: box-shadow 0.3s ease;
	overflow: hidden;
}

.answer--selected {
	box-shadow: 0px 0px 0px 3px #fecc80;
}
</style>
