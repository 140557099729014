<template>
	<div class="page__login">
		<div class="page__content">
			<div class="page__content__logo">
				<img
					width="144"
					height="80"
					alt="rxc logo"
					:src="require('@/assets/images/rox-logo.svg')"
				/>
			</div>
			<p class="page__content__title heading-4">
				Sign In / Sign Up to RXC
			</p>
			<p class="page__content__subheading body-2-normal">
				Please enter your email below.
			</p>
			<p class="page__content__label body-1-normal medium">E-mail</p>
			<input
				class="page__content__input"
				placeholder="Your Email"
				v-model="email"
			/>
		</div>
		<div class="spacer"></div>
		<base-button
			btnLabel="Continue"
			class="sd-btn-primary sd-btn-sm medium prev-btn page__button"
			:isLoading="isLoading"
			:isDisabled="isLoading || !email"
			@click="loginFlow(email)"
		></base-button>
	</div>
</template>
<script>
import { loginService } from "@/bloc/services";
import utility from "@/presentation/mixins/utility";
export default {
	mixins: [utility],
	data() {
		return {
			email: "",
			isLoading: false,
		};
	},
	methods: {
		async loginFlow(email) {
			try {
				this.isLoading = true;

				if (!this.isValidEmail(email)) {
					this.showNotification(
						"alert",
						"error_outline",
						"Invalid Email"
					);
					this.isLoading = false;
					return;
				}

				const isCustomerRegistered =
					await loginService.isCustomerRegistered(email);
				this.isLoading = false;

				if (isCustomerRegistered) {
					this.$emit("proceed-otp", email);
				} else {
					this.$emit("proceed-register", email);
				}
			} catch (error) {
				this.showNotification("alert", "error_outline", error);
				this.isLoading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.page {
	&__login {
		display: flex;
		height: 100%;
		flex-direction: column;
	}
	&__button {
		margin: auto 24px 24px 24px;
		margin-top: auto;
		height: 40px;
	}
	&__banner {
		height: 200px;
		position: relative;
		overflow: hidden;

		// TODO: find better way to style this
		img {
			position: absolute;
			max-width: 420px;
			height: 100%;
			width: 112%;
			min-height: 400px;
			min-width: 100%;
			top: -240px;
			left: -20px;
			transform: scale(1.2);
		}
	}
	&__content {
		padding: 0 24px;
		text-align: left;
		margin-top: 48px;

		&__logo {
			width: 144px;
			height: 80px;
			margin: 0 auto;
			margin-bottom: 164px;
		}
		&__title {
			margin-top: 24px;
			color: $secondary-color-100;
		}
		&__subheading {
			color: $secondary-color-40;
			margin-top: 16px;
		}
		&__answer {
			margin-top: 24px;
		}
		&__label {
			margin-top: 24px;
		}
		&__input {
			border: none;
			height: 52px;
			width: 100%;
			background: $primary-color-10;
			border-radius: 12px;
			padding: 16px;
			margin-top: 8px;
		}
	}
}
</style>
