import router from "../../router";
import { isEmpty } from "lodash";
import { homeService } from "@/bloc/services";
export default {
	methods: {
		isEmpty(expression) {
			return isEmpty(expression);
		},
		goTo(pageName) {
			return this.$router.push({
				name: pageName,
			});
		},
		goToWithParams(pageName, params) {
			return this.$router.push({
				name: pageName,
				params,
			});
		},
		goBack() {
			return this.$router.back();
		},
		isLoggedIn() {
			return this.$store.getters.isLoggedIn();
		},
		logout() {
			this.$store.dispatch("clearLoginToken");
			this.$store.dispatch("clearCustomer");
			this.goTo("LoginPage");
		},
		isValidEmail(email) {
			let pattern = /\S+@\S+\.\S+/;
			return pattern.test(email);
		},
		isValidPhone(phone) {
			if (!phone) return false;
			return (
				!Number.isNaN(phone) &&
				phone.toString().length === 8 &&
				(phone.toString().startsWith("8") ||
					phone.toString().startsWith("9"))
			);
		},
		showNotification(type, icon, message) {
			this.$store.dispatch("notification/updateNotification", {
				show: true,
				type: type,
				icon: icon,
				message: message,
				autoClose: true,
			});
		},
		isPartOfLinkedNudges(nudge, allNudges) {
			const isStart = nudge.tags
				?.map((it) => it.toLowerCase())
				?.includes("nudge_1");

			const isPart = allNudges.find((it) => {
				return (
					it.tags
						?.map((it) => it.toLowerCase())
						?.includes("nudge_1") && it.links?.includes(nudge.id)
				);
			});

			return isStart || isPart;
		},
		async refreshCustomerData() {
			let customer = await homeService.getCustomerDetails();
			this.$store.dispatch("setCustomer", customer);
		},
	},
};

export const goTo = (pageName, params) => {
	return router.push({ name: pageName, params });
};

export const isPartOfLinkedNudges = (nudge, allNudges) => {
	const isStart = nudge.tags
		?.map((it) => it.toLowerCase())
		?.includes("nudge_1");

	const isPart = allNudges.find((it) => {
		return (
			it.tags?.map((it) => it.toLowerCase())?.includes("nudge_1") &&
			it.links?.includes(nudge.id)
		);
	});

	return isStart || isPart;
};
