const state = () => ({});

const getters = {};

const mutations = {};

const actions = {};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
