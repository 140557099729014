class Question {
	constructor(id, title, type, answers, { displayImg } = {}) {
		this.id = id;
		this.title = title;
		this.type = type;
		this.answers = answers;
		this.displayImg = displayImg;
	}
}

export default Question;
