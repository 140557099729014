import { createStore } from "vuex";
import survey from "./modules/survey";
import register from "./modules/register";
import article from "./modules/article";
import notification from "./modules/notification";

export default createStore({
	state: {
		appVersion:
			process.env.NODE_ENV === "production"
				? process.env.VERSION
				: "0.0.0",
		token: localStorage.getItem("login-token") || "",
		customer: {},
		nudges: [],
	},
	mutations: {
		setLoginToken(state, token) {
			state.token = token;
			localStorage.setItem("login-token", token);
		},
		clearLoginToken(state) {
			state.token = "";
			localStorage.removeItem("login-token");
		},
		setCustomer(state, customer) {
			state.customer = customer;
		},
		clearCustomer(state) {
			state.customer = {};
		},
		setNudges(state, nudges) {
			state.nudges = nudges;
		},
	},
	actions: {
		setLoginToken({ commit }, token) {
			commit("setLoginToken", token);
		},
		clearLoginToken({ commit }) {
			commit("clearLoginToken");
		},
		setCustomer({ commit }, customer) {
			commit("setCustomer", customer);
		},
		clearCustomer({ commit }) {
			commit("clearCustomer");
		},
		clearSession({ commit }) {
			commit("clearCustomer");
			commit("clearLoginToken");
		},
		setNudges({ commit }, nudges) {
			commit("setNudges", nudges);
		},
	},
	getters: {
		getCustomer(state) {
			return state.customer;
		},
		getToken(state) {
			return state.token;
		},
		getNudges(state) {
			return state.nudges;
		},
		isLoggedIn(state) {
			return state.token != "";
		},
	},
	modules: {
		survey,
		register,
		article,
		notification,
	},
});
