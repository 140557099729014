<template>
	<layout-variant-two>
		<template v-slot:body>
			<template v-if="proceedOTP">
				<div class="otp-con">
					<one-time-password :email="email"></one-time-password>
				</div>
			</template>
			<template v-else>
				<login-email
					@proceed-otp="handleProceedOTP"
					@proceed-register="handleProceedRegister"
				></login-email>
			</template>
		</template>
	</layout-variant-two>
</template>
<script>
import OneTimePassword from "@/presentation/components/OneTimePassword.vue";
import LoginEmail from "./components/LoginEmail.vue";
import LayoutVariantTwo from "@/components/layout/LayoutVariantTwo.vue";
import utility from "@/presentation/mixins/utility.js";
export default {
	name: "LoginPage",
	mixins: [utility],
	components: {
		OneTimePassword,
		LoginEmail,
		LayoutVariantTwo,
	},
	props: {
		passedEmail: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			email: "",
			proceedOTP: false,
			isError: false,
		};
	},
	methods: {
		async handleProceedOTP(email) {
			this.proceedOTP = true;
			this.email = email;
		},
		async handleProceedRegister(email) {
			this.goToWithParams("RegisterPage", { passedEmail: email });
		},
	},
	mounted() {
		if (this.passedEmail) {
			this.handleProceedOTP(this.passedEmail);
		}
	},
};
</script>

<style lang="scss" scoped>
.otp-con {
	padding: 0 24px;
}
</style>
