<template>
	<div class="sd-base-con" v-if="!showLoadingScreen">
		<div class="notification__con">
			<base-notification
				:class="{ active: notification.show }"
			></base-notification>
		</div>
		<div
			class="sd-header"
			:style="[hasHeaderSlot ? { height: '56px' } : '']"
		>
			<slot name="header"></slot>
		</div>
		<div
			class="sd-main-con"
			:style="[
				hasHeaderSlot ? { height: 'calc(100% - 56px)' } : {},
				hasFooterSlot
					? { height: 'auto', 'padding-bottom': '56px' }
					: {},
			]"
		>
			<slot name="body"></slot>
		</div>
		<div class="sd-footer" v-if="hasFooterSlot">
			<slot name="footer"></slot>
		</div>
	</div>
	<div class="sd-base-con" v-else>
		<div class="sd-loading-con">
			<div class="dot-overtaking"></div>
			<p class="body-2-regular bold loading-text secondary-color-60">
				Loading ...
			</p>
		</div>
	</div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
	name: "LayoutVariantTwo",
	props: {
		showLoadingScreen: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, context) {
		const store = useStore();
		const notification = computed(() => store.state.notification);
		const hasHeaderSlot = computed(() => !!context.slots.header);
		const hasFooterSlot = computed(() => !!context.slots.footer);

		return {
			store,
			notification,
			hasHeaderSlot,
			hasFooterSlot,
		};
	},
};
</script>

<style scoped lang="scss">
.sd-header {
	width: 100%;
}
.sd-main-con {
	height: 100%;
	width: 100%;
	position: relative;
}

.sd-footer {
	width: 100%;
	height: 62px;
	position: fixed;
	background-color: $primary-color-10;
	bottom: 0;
}

.notification__con {
	min-width: 288px;
	position: fixed;
	left: 50%;
	top: 56px;
	transform: translateX(-50%);
	z-index: 1;
	pointer-events: none;
	width: 100%;

	.notification {
		width: 100%;
		margin: 0 auto;
		opacity: 0;
		transition: all 0.5s;

		&.active {
			opacity: 100%;
		}
	}
}

.sd-loading-con {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.loading-text {
		margin-top: 40px;
	}
}

.dot-overtaking {
	position: relative;
	width: 12px;
	height: 12px;
	border-radius: 6px;
	background-color: transparent;
	color: $primary-color-70;
	margin: -1px 0;
	box-shadow: 0 -20px 0 0;
	animation: dotOvertaking 2s infinite cubic-bezier(0.2, 0.6, 0.8, 0.2);
}

.dot-overtaking::before,
.dot-overtaking::after {
	content: "";
	display: inline-block;
	position: absolute;
	top: 0;
	left: 0;
	width: 12px;
	height: 12px;
	border-radius: 6px;
	background-color: transparent;
	color: $primary-color-60;
	box-shadow: 0 -20px 0 0;
}

.dot-overtaking::after {
	color: $primary-color-50;
}

.dot-overtaking::before {
	animation: dotOvertaking 2s infinite cubic-bezier(0.2, 0.6, 0.8, 0.2);
	animation-delay: 0.3s;
}

.dot-overtaking::after {
	animation: dotOvertaking 1.5s infinite cubic-bezier(0.2, 0.6, 0.8, 0.2);
	animation-delay: 0.6s;
}

@keyframes dotOvertaking {
	0% {
		transform: rotateZ(0deg);
	}
	100% {
		transform: rotateZ(360deg);
	}
}

@media (min-width: 480px) {
	.sd-main-con,
	.sd-header,
	.notification__con,
	.sd-footer {
		max-width: 480px;
	}
}

@media (min-width: 672px) {
	.sd-main-con,
	.sd-header,
	.notification__con,
	.sd-footer {
		max-width: 672px;
	}
}
</style>
