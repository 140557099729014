const questions = [
	{
		title: "What is your first name?",
		subtitle: "My first name is",
		placeholder: "Enter your first name",
		type: "input",
		apiField: "firstName",
		answer: "",
	},
	{
		title: "What is your contact number?",
		subtitle: "My phone number is",
		placeholder: "Enter your phone number",
		type: "input",
		apiField: "phone",
		answer: "",
	},
	// {
	// 	title: "What year you were born",
	// 	subtitle: "I was born in",
	// 	type: "date",
	// 	apiField: "dateOfBirth",
	// 	answer: "",
	// },
	// {
	// 	title: "How do you identify?",
	// 	subtitle: "At times, we'll provide gender specific recommendation",
	// 	type: "single-ans",
	// 	apiField: "gender",
	// 	answers: [
	// 		{
	// 			title: "Man",
	// 			enum: "MALE",
	// 			selectedAnswer: false,
	// 		},
	// 		{
	// 			title: "Woman",
	// 			enum: "FEMALE",
	// 			selectedAnswer: false,
	// 		},
	// 	],
	// },
];

export default questions;
