import { createRouter, createWebHashHistory } from "vue-router";
import WelcomePage from "@/presentation/pages/page-start/WelcomePage.vue";
import LandingCarousel from "@/presentation/pages/page-start/LandingCarousel.vue";
import LoginPage from "@/presentation/pages/page-login/LoginPage.vue";
import RegisterPage from "@/presentation/pages/page-register/RegisterPage.vue";
import store from "@/store";
import LogRocket from "logrocket";

const routes = [
	{
		path: "/",
		redirect: "/welcome",
	},
	{
		path: "/welcome",
		name: "WelcomePage",
		component: WelcomePage,
	},
	{
		path: "/landing-carousel",
		name: "LandingCarousel",
		component: LandingCarousel,
	},
	{
		path: "/register",
		name: "RegisterPage",
		component: RegisterPage,
		props: true,
		meta: { notAllowLoggedInUser: true },
	},
	{
		path: "/login",
		name: "LoginPage",
		component: LoginPage,
		props: true,
		meta: { notAllowLoggedInUser: true },
	},
	// lazy load other pages
	{
		path: "/home",
		name: "HomePage",
		component: function () {
			return import("../presentation/pages/page-home/HomePage.vue");
		},
		meta: { requireLogin: true },
	},
	{
		path: "/points",
		name: "PointsPage",
		component: function () {
			return import("../presentation/pages/page-points/PointsPage.vue");
		},
		meta: { requireLogin: true },
	},
	{
		path: "/404",
		name: "ErrorPage",
		component: function () {
			return import("../presentation/pages/page-message/ErrorPage.vue");
		},
	},
	{
		path: "/survey/:nudgeId",
		name: "SurveyPage",
		component: function () {
			return import("../presentation/pages/page-survey/SurveyPage.vue");
		},
		meta: { requireLogin: true },
	},
	{
		path: "/article/:nudgeId/:shareToken?",
		name: "ArticlePage",
		component: function () {
			return import("../presentation/pages/page-article/ArticlePage.vue");
		},
		props: true,
	},
	{
		path: "/surprise/:nudgeId",
		name: "SurprisePage",
		component: function () {
			return import(
				"../presentation/pages/page-surprise/SurprisePage.vue"
			);
		},
		props: true,
		meta: { requireLogin: true },
	},
	{
		path: "/faq",
		name: "FAQ",
		component: function () {
			return import("../presentation/pages/page-faq/FaqPage.vue");
		},
	},
	{
		path: "/thank-you",
		name: "ThankYouPage",
		component: function () {
			return import(
				"../presentation/pages/page-message/ThankYouPage.vue"
			);
		},
		props: true,
	},
	{
		path: "/:pathMatch(.*)*",
		redirect: "/404",
	},
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
	// eslint-disable-next-line no-unused-vars
	scrollBehavior(to, from, savedPosition) {
		// always scroll to top
		return { top: 0 };
	},
});

router.beforeEach((to, from, next) => {
	if (
		to.matched.some((record) => record.meta.requireLogin) &&
		!store.getters.isLoggedIn
	) {
		next({
			path: "/login",
			// params: { redirect: { name: to.name, params: to.params } },
			query: { redirect: to.fullPath },
		});
	}
	// this is to prevent user accessing login page after logged in using navigation guard
	else if (store.getters.isLoggedIn) {
		const customer = store.getters.getCustomer;
		const isUsingLogRocket = process?.env?.VUE_APP_LOG_ROCKET_ID
			? true
			: false;

		if (isUsingLogRocket && customer?.email)
			LogRocket.identify(customer.email);

		const notAllowLoggedInUser = to.matched.some(
			(route) => route.meta.notAllowLoggedInUser
		);

		if (notAllowLoggedInUser) {
			next({ path: "/home" });
		} else {
			next();
		}
	} else {
		next();
	}
});

export default router;
