import { BFM_API_CONNECTOR } from "./apiConnector";
import { BFM_CONFIG } from "./apiConfig";
import { EUNOIA_API_CONNECTOR } from "./apiConnector";
import { EUNOIA_CONFIG } from "./apiConfig";
import store from "../store/index";

// https://app.swaggerhub.com/apis/mkhairul6/BetterForMe/1.0#/Customer/post_api_1_0_otp
export const sendOTP = async (email) => {
	const encodedEmail = encodeURIComponent(email);
	const path = `/otp?account=${BFM_CONFIG.accountCode}&email=${encodedEmail}`;
	const { post } = BFM_API_CONNECTOR({});
	try {
		const data = await post(path);
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(error);
	}
};

// https://app.swaggerhub.com/apis/mkhairul6/BetterForMe/1.0#/Customer/post_api_1_0_login
export const loginWithOTP = async (payload) => {
	const path = `/login?account=${BFM_CONFIG.accountCode}`;
	const { post } = BFM_API_CONNECTOR({});
	try {
		const data = await post(path, new URLSearchParams(payload));
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(error);
	}
};

// https://app.swaggerhub.com/apis/mkhairul6/BetterForMe/1.0#/Customer/get_api_1_0_details
export const getDetails = async () => {
	const path = `/details`;
	const { get } = BFM_API_CONNECTOR({
		authToken: store.getters.getToken,
		account: BFM_CONFIG.accountCode,
	});
	try {
		const data = await get(path);
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(error);
	}
};

// EU really needs to update their docs and API error message, app needs to be input for this API to work
// https://staging.eunoia.asia/docs/#register-a-customer
export const registerCustomer = async (payload) => {
	const path = `/customer/register/${EUNOIA_CONFIG.brandCode}?`;
	const { post } = EUNOIA_API_CONNECTOR({});
	const params = {
		...{ app: EUNOIA_CONFIG.app },
		...payload,
	};
	try {
		const data = await post(path, new URLSearchParams(params));
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(error);
	}
};

// https://staging.eunoia.asia/docs/#querying-whether-a-customer-is-registered
export const checkRegistration = async (email) => {
	const path = `/customer/registered/${EUNOIA_CONFIG.brandCode}`;
	const { post } = EUNOIA_API_CONNECTOR({
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});

	let bodyFormData = new FormData();
	bodyFormData.append("email", email);
	try {
		const data = await post(path, bodyFormData);
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(error);
	}
};
